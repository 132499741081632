<template>
    <b-overlay :show="loading">
        <!-- <div class="d-flex justify-content-end mb-2">
            <b-button v-if="hasPermissionPay" @click.prevent="printEvidence" variant="primary">
                <feather-icon icon="PrinterIcon"></feather-icon>
                Cetak Pembayaran
            </b-button>
            <b-button v-else @click.prevent="print" variant="primary">
                <feather-icon icon="PrinterIcon"></feather-icon>
                Cetak Form Penerimaan
            </b-button>
        </div> -->
        <!-- {{ penerimaan }} -->
        <b-row>
            <b-col md="12" sm="12">

                <b-card class="mb-2">
                    <header class="d-flex justify-content-between align-items-center mb-1">
                        <h4>{{ pembelian ? pembelian.no : '' }}</h4>
                        <div class="d-flex align-item-center">
                            <p>Tanggal Pembelian: {{ pembelian ? humanDate(pembelian.tanggal) : "" }}</p>
                            <!-- <div v-if="hasPermissionPay && pembelian && pembelian.izin == 1">
                                <b-button :disabled="pembelian.informasi.hutang < 1" variant="primary" class="ml-2"
                                    size="sm" @click.prevent="$router.push(`/pembelian/bayar/${pembelian.id}`)">Input
                                    Pembayaran</b-button>
                            </div> -->
                        </div>
                    </header>
                    <b-row>
                        <b-col sm="12" md="6" lg="4">
                            <span class="d-block">Pembuat</span>
                            <strong class="d-block mb-1">{{ pembelian ? pembelian.creator.nama_lengkap : "" }}</strong>
                            <span class="d-block">Tanggal Perkiraan Barang Sampai</span>
                            <strong class="d-block mb-1">{{ pembelian ? humanDate(pembelian.jatuh_tempo) :
                                "-" }}</strong>
                            <span class="d-block">Keterangan</span>
                            <strong class="d-block mb-2">{{ pembelian ? (pembelian.keterangan == null ? null :
                                pembelian.keterangan) : "" }}</strong>
                            <!-- <b-badge class="mb-1" variant="success" v-if="pembelian.informasi.hutang < 1">Lunas</b-badge>
                    <b-badge class="mb-1" variant="danger" v-else>Belum Lunas</b-badge> -->
                        </b-col>
                        <b-col sm="12" md="6" lg="4" v-if="!hasPermissionPay">
                            <span class="d-block mb-1">Informasi Supplier</span>
                            <strong class="d-block mb-1">Instansi: {{ pembelian ? pembelian.supplier ?
                                pembelian.supplier.instansi : null : "" }}</strong>
                            <strong class="d-block mb-1">Email: {{ pembelian ? pembelian.supplier ?
                                pembelian.supplier.email : null : "" }}</strong>
                            <strong class="d-block mb-1">No. HP: {{ pembelian ? pembelian.supplier ?
                                pembelian.supplier.nohp : null : "" }}</strong>
                        </b-col>
                        <!-- <b-col sm="12" md="6" lg="4" v-if="pembelian.informasi">
                    <span class="d-block mb-1">Informasi Pembayaran</span>
                    <strong class="d-block mb-1">Total Bayar: {{formatRupiah(pembelian.informasi.total_bayar)}}</strong>
                    <strong class="d-block mb-1">Total Pembelian: {{formatRupiah(pembelian.informasi.total_pembelian)}}</strong>
                    <strong class="d-block mb-1">Hutang: {{formatRupiah(pembelian.informasi.hutang)}}</strong>
                </b-col> -->
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <!-- <b-col md="2" sm="6" class="my-1">
                <b-form-group class="mb-0" :label="$t('Per page')" label-size="sm" label-for="perPageSelect">
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
                </b-form-group>
            </b-col>
            <b-col md="2" sm="6" class="my-1">
                <b-form-group :label="$t('Sort')" label-size="sm" label-for="sortBySelect" class="mb-0">
                    <b-input-group size="sm">
                        <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" @change="getData()"
                            class="w-100">
                            <option :value="false">Asc</option>
                            <option :value="true">Desc</option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>
            </b-col> -->
            <b-col md="12">
                <!-- <b-row> -->
                <!-- <b-col> -->
                <b-card class="mb-2">
                    <b-row>
                        <b-col md="12" sm="12">
                            <h4 class="">{{ $t('Item Details') }}<span v-if="rincian && rincian.length > 0">
                                    ({{ rincian.length
                                    }})</span></h4>
                        </b-col>
                        <b-col md="8" sm="12" class="">
                            <b-form-group label-size="sm">
                                <b-input-group>
                                    <b-form-input id="filter-input" v-model="filter" type="search"
                                        placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">{{ $t('Clear') }}</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-button v-if="penerimaan ? penerimaan.selesai == 0 : null" block variant="primary"
                                @click.prevent="chooseBarang">{{ $t('Additional Items')
                                }}</b-button>
                            <barang-modal @submit="onChooseBarang"></barang-modal>
                        </b-col>
                        <b-col md="12">
                            <b-table small responsive :fields="fields" :items="rincian" :filter="filter"
                                :filter-included-fields="filterOn">
                                <template #cell(kode)="{ item }">
                                    <div>{{ item.barang_short ? item.barang_short.kode : null }}</div>
                                </template>
                                <template #cell(barang)="{ item }">
                                    <span v-if="item.barang_short">
                                        {{ item.barang_short.varian }}<br>
                                        <small>{{ item.barang_short.kategori ? item.barang_short.kategori.kategori :
                                            '-'
                                            }}</small> /
                                        <small>{{ item.barang_short.nama }}</small>
                                    </span>
                                    <i v-else class="text-danger">Barang tidak ada.</i>
                                </template>
                                <template #cell(qty)="{ item }">
                                    <div>{{ item.pembelian.qty }} /{{ item.barang_short && item.barang_short.satuan
                                        ?
                                        item.barang_short.satuan.satuan.toLowerCase() : '' }}</div>
                                </template>
                                <template #cell(sisa)="{ item }">
                                    {{ item.sisa }}
                                </template>
                                <template #cell(jumlah)="{ item }">
                                    <!-- <template v-if="item.sisa > 0"> -->
                                    <b-form-input type="number" v-model="item.jumlah" />
                                    <!-- </template> -->
                                    <!-- <template v-else> -->
                                    <!-- <b-form-input type="number" v-model="item.sisa" disabled /> -->
                                    <!-- <span class="text-success">{{ $t('Sudah diterima') }}</span> -->
                                    <!-- </template> -->
                                </template>
                                <template #cell(keterangan)="{ item }">
                                    <template v-if="penerimaan.selesai == 1">
                                        {{ item.keterangan }}
                                        <!-- <b-form-input disabled v-model="item.keterangan" /> -->
                                    </template>
                                    <template v-else>
                                        <!-- {{ item.keterangan }} -->
                                        <b-form-input v-model="item.keterangan" />
                                    </template>
                                    <!-- <div>{{ item.qty }} /{{ item.barang && item.barang.satuan ? item.barang.satuan.satuan.toLowerCase() : ''  }}</div> -->

                                </template>
                                <template #cell(selisih)="{ item }">
                                    {{ item.selisih = item.jumlah - item.pembelian.qty }}
                                </template>
                                <template #cell(harga_beli)="{ item }">
                                    {{ formatRupiah(item.harga_beli) }}
                                </template>
                                <template #cell(total)="{ item }">
                                    {{ formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty)) }}
                                </template>
                            </b-table>

                        </b-col>
                    </b-row>
                    <hr v-if="selectedBarangs.length > 0">
                    <b-row v-if="selectedBarangs.length > 0">
                        <b-col md="12" sm="12">
                            <h4>{{ $t('Additional Items') }}<span v-if="selectedBarangs && selectedBarangs.length > 0">
                                    ({{ selectedBarangs.length
                                    }})</span></h4>
                        </b-col>
                        <b-col md="12">
                            <b-table small responsive :fields="fieldstambahan" :items="selectedBarangs" :filter="filter"
                                :filter-included-fields="filterOn">
                                <template #table-colgroup="{ fields }">
                                    <col v-for="field in fields" :key="field.key"
                                        :style="{ width: field.key == 'no' ? '20px' : '' }" />
                                </template>
                                <template #cell(no)="{ index }">
                                    {{ 1 + index }}
                                </template>
                                <template #cell(kategori)="{ item }">
                                    {{ item.kategori ? item.kategori.kategori : '-' }}
                                </template>
                                <template #cell(no_container)="{ item }">
                                    <b-form-input v-model="item.no_container"
                                        placeholder="Ex: MRT123213"></b-form-input>
                                </template>
                                <template #cell(nama)="{ item }">
                                    <span v-if="item">
                                        {{ item.varian_item[0].varian }}<br>
                                        <small>{{ item.kategori.kategori }}</small> /
                                        <small>{{ item.nama }}</small>
                                    </span>
                                    <i v-else class="text-danger">Barang tidak ada.</i>
                                </template>
                                <template #cell(no_seal)="{ item }">
                                    <b-form-input v-model="item.no_seal" placeholder="Ex: GR123"></b-form-input>
                                </template>
                                <template #cell(harga_beli)="{ item }">
                                    <div>{{ formatRupiah(item.harga_beli) }}</div>
                                    <strong class="text-danger" v-if="item.diskon && item.diskon > 0"> diskon {{
                                        formatRupiah(item.diskon)
                                        }}
                                    </strong>
                                </template>
                                <template #cell(diskon)="{ item }">
                                    {{ formatRupiah(item.diskon) }}
                                </template>
                                <template #cell(qty)="{ item }">
                                    <b-input-group size="lg" :append="item.satuan ?
                                        item.satuan.satuan :
                                        null">
                                        <b-form-input type="number" class="w-70" v-model="item.qty"></b-form-input>
                                    </b-input-group>
                                </template>
                                <!-- /<small>{{
                                        item.satuan ?
                                            item.satuan.satuan :
                                            "" }}</small> </template> -->
                                <template #cell(total)="{ item }">
                                    {{ formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty)) }}
                                </template>
                                <template #cell(action)="{ item, index }">
                                    <feather-icon icon="MinusCircleIcon" size="24" class="text-danger cursor-pointer"
                                        @click="removeBarang(item, index)"></feather-icon>
                                </template>
                                <template #cell(keterangan)="{ item }">
                                    <b-form-input v-model="item.keterangan" />
                                </template>
                            </b-table>

                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col md="12">
                            <b-row>
                                <b-col md="8">
                                    <template v-if="penerimaan ? penerimaan.selesai == 1 : null">
                                        <!-- <b-form-textarea :placeholder="$t('Note Purchase')"
                                        v-model="penerimaan.keterangan"></b-form-textarea> -->
                                        <h5>{{ $t('Note Purchase') }} :</h5>
                                        {{ penerimaan ? penerimaan.keterangan : null }}
                                    </template>
                                    <template v-else>
                                        <h5>{{ $t('Note Purchase') }} :</h5>
                                        <b-form-textarea :placeholder="$t('Note Purchase')"
                                            v-model="keterangan"></b-form-textarea>
                                    </template>
                                </b-col>
                                <b-col md="4">
                                    <b-button v-if="penerimaan ? penerimaan.selesai == 0 : null" block variant="success"
                                        @click="submit">{{
                                            $t('Save') }}</b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
                <!-- </b-col> -->
                <!-- </b-row> -->
                <!-- <div class="d-flex justify-content-end align-items-center">
                    <h4>Total Pembelian: </h4>
                    <h2 class="ml-2">Rp {{pembelian.informasi ? formatRupiah(pembelian.informasi.total_pembelian) : ''}}</h2>
                </div> -->
            </b-col>
            <!-- <b-col md="6"> <h4 class="mb-1">{{ $t('Rincian Hasil Penghitungan Pembelian Barang') }}<span v-if="pembelian.rincian && pembelian.rincian.length > 0"> ({{ pembelian.rincian.length }})</span></h4>
   
            <b-card class="mb-2">
                <b-table small responsive :fields="fields2" :items="pembelian.rincian">
                    <template #cell(barang)="{item}">
                        <span v-if="item.barang">
                            {{item.barang.varian_item[0].varian}}<br>
                            <small>{{item.barang.kategori.kategori}}</small> /
                            <small>{{item.barang.nama}}</small>
                        </span>
                        <i v-else class="text-danger">Barang tidak ada.</i>
                    </template>
                    <template #cell(qty)="{item}">
                        <b-form-input :value="item.qty" v-model="item.qtywh" type="number"></b-form-input>
                        <div>/{{ item.barang && item.barang.satuan ? item.barang.satuan.satuan.toLowerCase() : ''  }}</div>
                        
                    </template>
                    <template #cell(diskon)="{item}">
                        {{formatRupiah(item.diskon)}}
                    </template>
                    <template #cell(harga_beli)="{item}">
                        {{formatRupiah(item.harga_beli)}}
                    </template>
                    <template #cell(total)="{item}">
                        {{ formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty)) }}
                    </template>
                </b-table>
                <div class="d-flex justify-content-end align-items-center">
                    <h4>Total Pembelian: </h4>
                    <h2 class="ml-2">Rp {{pembelian.informasi ? formatRupiah(pembelian.informasi.total_pembelian) : ''}}</h2>
                </div>
            </b-card>
        </b-col> -->
        </b-row>
        <section class="d-flex align-items-center justify-content-end">
            <div v-if="hasPermissionApprove && pembelian">
                <b-button variant="success" v-if="pembelian.izin == 0" @click.prevent="approve">Approve
                    Pembelian</b-button>
            </div>
            <div v-if="hasPermissionCancel && pembelian" class="ml-1">
                <b-button variant="outline-danger" v-if="pembelian.izin == 0" @click.prevent="cancel">Batalkan
                    Pembelian</b-button>
            </div>
        </section>

    </b-overlay>
</template>
<script>
import { BModal, BInputGroupAppend, BFormTextarea, BCard, BFormInput, BTable, BBadge, BRow, BCol, BButton, BOverlay, BFormGroup, BFormSelect, BInputGroup, } from 'bootstrap-vue'
import BarangModal from "./Modal.vue"; export default {
    components: {
        BarangModal, BModal, BInputGroupAppend, BFormTextarea, BCard, BFormInput, BTable, BBadge, BRow, BCol, BButton, BOverlay, BFormGroup, BFormSelect, BInputGroup,
    },
    data: () => ({
        keterangan: null,
        selectedBarangs: [],
        pageOptions: [10, 20, 50, 100],
        sortBy: "desc",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        perPage: 10,
        totalRows: 1,
        currentPage: 1,
        listStatus: [],
        loading: false,
        pembelian: null,
        penerimaan: null,
        rincian: [],
        gudang: null,
        fields: [
            // {
            //     key: 'no_container',
            //     label: 'No. Container'
            // },
            {
                key: 'kode',
                label: 'Barcode'
            },
            {
                key: 'barang',
                label: 'Nama Barang'
            },
            {
                key: 'qty',
                label: 'Qty Pembelian'
            },
            {
                key: 'sisa',
                label: 'Sisa'
            },
            {
                key: 'jumlah',
                label: 'Cek Qty'
            },
            {
                key: 'selisih',
                label: 'Selisih'
            },
            {
                key: 'Keterangan',
                label: 'Keterangan'
            },
            // {
            //     key: 'diskon',
            //     label: 'Diskon'
            // },
            // {
            //     key: 'harga_beli',
            //     label: 'Harga Beli'
            // },
            // {
            //     key: 'total',
            //     label: 'Total'
            // }
        ],
        fieldstambahan: [
            // { key: "no_container", label: "No. Container" },
            // { key: "no_seal", label: "No. Seal" },
            // { key: "kategori", label: "kategori" },
            { key: "kode", label: "Barcode" },
            { key: "nama", label: "Nama Barang" },
            //   { key: "harga_beli", label: "Harga Beli" },
            { key: "qty", label: "Qty" },
            { key: "keterangan", label: "Keterangan" },
            { key: "action", label: "" },
        ],
        fields2: [
            // {
            //     key: 'no_container',
            //     label: 'No. Container'
            // },
            // {
            //     key: 'no_seal',
            //     label: 'No. Seal'
            // },
            {
                key: 'barang',
                label: 'Nama Barang'
            },
            {
                key: 'qty',
                label: 'Qty'
            },
            // {
            //     key: 'diskon',
            //     label: 'Diskon'
            // },
            // {
            //     key: 'harga_beli',
            //     label: 'Harga Beli'
            // },
            // {
            //     key: 'total',
            //     label: 'Total'
            // }
        ],
        hasPermissionApprove: false,
        hasPermissionPay: false,
        hasPermissionCancel: false,
        confirmOption: {
            title: 'Setujui Pembelian ini?',
            text: `Dengan ini finance dapat meng-inputkan pembayaran pembelian`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger ml-1',
            },
            buttonsStyling: false
        }
    }),
    methods: {
        onChooseBarang(selectedBarangs) {
            selectedBarangs.map((barang) => {
                barang.no_container = null;
                barang.no_seal = null;
                barang.keterangan = null;
                this.selectedBarangs.push(barang);
            });
            this.$bvModal.hide("barang-modal");
        },
        chooseBarang() {
            this.$bvModal.show("barang-modal");
        },
        async printEvidence() {
            try {
                this.loading = true
                const response = await this.$store.dispatch('pembelian/printPembayaran', this.$route.params.id)
                this.loading = false
                window.open(URL.createObjectURL(response))
            }
            catch (e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async print() {
            try {
                this.loading = true
                const response = await this.$store.dispatch('pembelian/print', this.$route.params.id)
                this.loading = false
                window.open(URL.createObjectURL(response))
            }
            catch (e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async doUpdate(payload) {
            try {
                const actionName = payload.tolak == 1 ? 'pembelian/cancel' : 'pembelian/save'
                await this.$store.dispatch(actionName, [payload])
                const params = {
                    order: 'desc'
                }
                if (this.isGM || this.isOwner || this.isCEO) params.jenis = 2
                await this.$store.dispatch('pembelian/getData', params)

                this.getPembelian()

                return Promise.resolve()
            }
            catch (e) {
                this.displayError(e)
                return Promise.reject(e)
            }
        },
        submit() {
            this.$swal({
                title: 'Anda yakin?',
                text: `Invoice pembelian ini sudah di checking dan masuk stok`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    console.log('barang tambah', this.selectedBarangs)

                    // this.rincian.map((hem) => {
                    //     hem.penerimaan_id = this.penerimaan.id
                    //     hem.barang_id = hem ? (hem.barang_short ? hem.barang_short.id : null) : null
                    //     hem.jumlah = hem.jumlah
                    //     hem.keterangan = hem.keterangan
                    // });
                    // this.rincian.map((hem) => {
                    //     hem.penerimaan_id = this.penerimaan.id
                    //     hem.barang_id = hem ? (hem.barang_short ? hem.barang_short.id : null) : null
                    //     hem.jumlah = hem.jumlah
                    //     hem.outside_of_purchase = 1
                    //     hem.keterangan = hem.keterangan
                    // });
                    let aslina = []
                    this.rincian.map((hem) => {
                        aslina.push({
                            // penerimaan_id: this.penerimaan.id,
                            barang_id: hem ? (hem.barang_short ? hem.barang_short.id : null) : null,
                            jumlah: hem.jumlah,
                            keterangan: hem.keterangan,
                        });
                    });
                    this.selectedBarangs.map((hem) => {
                        aslina.push({
                            // penerimaan_id: this.penerimaan.id,
                            barang_id: hem ? hem.id : null,
                            jumlah: parseInt(hem.qty),
                            outside_of_purchase: 1,
                            keterangan: hem.keterangan,
                        });
                    });
                    // this.rincian.push(this.selectedBarangs)
                    const payload = {
                        items: aslina,
                        penerimaan_id: this.penerimaan.id,
                        keterangan: this.keterangan
                    }

                    // console.log('AJIIIIII', payload)

                    // payload.penerimaan_id = this.penerimaan.id
                    // payload.barang_id = this.rincian.barang_short.id
                    // payload.qty = this.rincian.jumlah

                    this.$store.dispatch('pembelian/tempatkan', payload)
                        .then(() => {
                            this.displaySuccess({
                                message: 'Berhasil'
                            })
                            this.getPembelian()
                        })
                        .catch(e => {
                            this.displayError(e)
                            return false
                        })
                }
            })

        },
        approve() {
            this.confirmOption.title = 'Setujui Pembelian ini?'
            this.confirmOption.text = 'Dengan ini finance dapat meng-inputkan pembayaran pembelian'
            this.$swal(this.confirmOption)
                .then(async res => {
                    if (res.value) {
                        const payload = {
                            id: this.$route.params.id,
                            id_akun: this.pembelian.id_akun,
                            jenis: this.isGM || this.isOwner || this.isCEO ? 2 : 1,
                            izin: 1
                        }
                        this.doUpdate(payload)
                            .then(() => {
                                this.displaySuccess({
                                    message: 'Pembelian berhasil disetujui'
                                })
                            })
                    }
                })
        },
        cancel() {
            this.confirmOption.title = 'Batalkan Pembelian ini?'
            this.confirmOption.text = 'Anda akan membatalkan pembelian ini'
            this.$swal(this.confirmOption)
                .then(async res => {
                    if (res.value) {
                        const payload = {
                            id: this.$route.params.id,
                            jenis: this.isGM ? 2 : 1,
                            tolak: 1
                        }
                        this.doUpdate(payload)
                            .then(() => {
                                this.displaySuccess({
                                    message: 'Pembelian berhasil ditolak'
                                })
                            })
                    }
                })
        },
        getPembelian() {
            // const pembelians = this.$store.state.pembelian.datas
            // if (!pembelians) {
            //     this.$router.push('/pembelian')
            // }

            // const pembelian = pembelians.find(item => item.id == this.$route.params.id)
            // if (!pembelian) {
            //     this.$router.push('/pembelian')
            // }

            // pembelian.rincian.map((barang) => {
            //     barang.jumlah = barang.qty;
            // });

            // if (this.$route.params.id) {
            // let barang = this.$store.getters["barang/getBarang"](this.$route.params.id);
            // if (!barang) {
            // let barang = this.$store.dispatch(
            //     "pembelian/penerimaan",
            //     this.$route.params.id
            // );
            this.$store
                .dispatch("pembelian/penerimaan", this.$route.params.id)
                .then((response) => {
                    // console.log('CEK', response)
                    // let items = JSON.parse(JSON.stringify(this.$store.state.pembelian.penerimaandatas));
                    // let items_total = this.$store.state.pembelian.totals;
                    this.penerimaan = response;
                    this.pembelian = response.pembelian
                    this.rincian = response.item_rincian
                    //   this.items = stocks;
                })
                .catch((e) => {
                    this.displayError(e);
                    return false;
                });
            // console.log("barang", barang);
            // }
            // var newVarian = [];
            // barang.varian_item.map((item) => {
            //     newVarian.push({
            //         barang_id: item.barang_id,
            //         kode: item.kode,
            //         varian: item.varian,
            //         harga_dasar: item.harga_dasar,
            //         no_batch: item.no_batch,
            //         expire_date: item.expire_date,
            //         text: item.varian,
            //         value: item.barang_id,
            //     });
            // });
            // }
            // this.pembelian = pembelian
        },
        async getStatusApprove() {
            const menu = await this.currentMenu(this.$route.meta.parent)
            const params = {
                level_id: this.user.level.id
            }
            if (menu) {
                params.menu_id = menu.id
            }
            this.listStatus = await this.$store.dispatch('statusrole/getData', params)

            // data_status => 1 approve gm, data_status => 2 pay finance
            const hasPermissionApprove = this.listStatus.some(status => status.data_status == 1)
            const hasPermissionPay = this.listStatus.some(status => status.data_status == 2)
            const hasPermissionCancel = this.listStatus.some(status => status.data_status == 3)

            this.hasPermissionApprove = hasPermissionApprove
            this.hasPermissionPay = hasPermissionPay
            this.hasPermissionCancel = hasPermissionCancel
        },
    },
    computed: {
        canPay() {
            if (this.pembelian.informasi && this.pembelian.informasi.total_bayar) {
                const { total_bayar, total_pembelian } = this.pembelian.informasi
                return this.pembelian.izin == 1 && (total_bayar < total_pembelian)
            }
            return false
        }
    },
    mounted() {
        this.getPembelian()
        // await this.getStatusApprove()
    }
}
</script>