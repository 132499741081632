<template>
  <b-modal id="barang-modal" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true">
    <header class="mb-1">
      <h5>Barang dipilih ({{ selectedBarangs.length }}).</h5>
      <b-row>
        <b-col md="2" sm="2" class="my-1">
          <b-form-group class="mb-0" :label="$t('Per page')" label-for="perPage" size="sm">
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="2" class="my-1">
          <b-form-group :label="$t('Sort')" label-size="sm" label-for="sortBySelect" class="mb-0">
            <b-input-group size="sm">
              <!-- <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
</b-form-select> -->
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="2" sm="2" class="my-1">
          <b-form-group class="mb-0" :label="$t('Category')" label-for="category" size="sm">
            <b-form-select id="categorySelect" v-model="category" size="sm" :options="categoryOptions"
              @change="getData()">
              <b-form-select-option :value="null">{{ $t('All') }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="6" class="my-1">
          <b-form-group :label="$t('Filter')" label-for="filterInput" class="mb-0" size="sm">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Tekan enter untuk cari" size="sm"
                @keydown.enter.prevent="getData()"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-primary" @click="getData()" size="sm">
                  Cari
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col md="2" sm="2" class="my-1">
          <b-form-group class="mb-0" :label="$t('Category')" label-for="category" size="sm">
            <b-form-select id="categorySelect" v-model="category" size="sm" :options="categoryOptions"
              @change="getData()">
              <b-form-select-option :value="null">{{ $t('All') }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row> -->
    </header>
    <b-overlay :show="loading">
      <template v-if="localBarangs.length > 0">
        <b-table striped small hover responsive :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection" :fields="fields" :items="localBarangs">
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" :style="{
              width: field.key === 'diskon' ? '150px' :
                field.key === 'nama' ? '400px' :
                  field.key === 'satuan' ? '80px' :
                    field.key === 'checkbox' ? '70px' : '180px'
            }">
          </template>
          <template #head(checkbox)>
            <b-form-checkbox v-model="allChecked"></b-form-checkbox>
          </template>
          <template #cell(checkbox)="{ item }">
            <b-form-checkbox v-model="selectedBarangs" :value="item"></b-form-checkbox>
          </template>
          <template #cell(fotopro)="{ item }">
            <template v-if="item.photo && item.photo.length > 0" class="image-gallery">
              <!-- <img v-for="(image, index) in item.photo" :key="index" :src="image.path" :alt="image.path"
                  class="viewer-image" ref="imageElement" /> -->
              <img style="width: 50px;" @click="openModal(item.photo[0].path, item.barcode)" :src="item.photo[0].path"
                alt="Image description" />
              <b-modal id="image-modal" title="Preview Photo Product Item and Barcode" ref="imageModal"
                :hide-footer="true" size="lg" centered>
                <div ref="imageViewer">
                  <img v-if="currentImage" :src="currentImage" class="img-fluid" alt="Full-size" />
                  <small v-else class="text-danger">Photo product item not yet created</small>
                  <br>
                  <img v-if="currentBarcode" :src="currentBarcode" class="img-fluid" alt="Full-size" />
                  <small v-else class="text-danger">Barcode not yet created</small>
                </div>
              </b-modal>
            </template>
            <small v-else class="text-danger">Photo product item not yet created</small>
            <feather-icon v-else icon="Icon" size="32" />
            <br>
            <img v-if="item.barcode" @click="openModal(item.photo.length > 0 ? item.photo[0].path : null, item.barcode)"
              style="width: 75px;" :src="item.barcode" alt="Foto" />
            <small v-else class="text-danger">Barcode not yet created</small>
            <feather-icon v-else icon="Icon" size="32" />
          </template>
          <template #cell(kode)="{ item }">
            {{ item.kode }}<br>
            <small>{{ item.varian_item[0].no_batch }}</small>
            <!-- <small>{{ item.nama }}</small> -->
          </template>
          <template #cell(kategori)="{ item }">
            {{ item.kategori ? item.kategori.kategori : '-' }}
          </template>
          <template #cell(nama)="{ item }">
            <span v-if="item">
              {{ item.nama }}<br>
              <small>{{ item.kategori.kategori }} / {{ item.varian_item[0].varian }}</small>
            </span>
            <i v-else class="text-danger">Data tidak ada.</i>
          </template>
          <template #cell(harga_dasar)="{ item }">
            <strong>{{ formatRupiah(item.varian_item[0].harga_dasar) }}</strong>
          </template>
          <template #cell(harga_dasar_baru)="{ item, index }">
            <b-form-input v-if="item.isChangePrice" @keyup="doFormatRupiahHDB(index)"
              v-model="item.harga_dasar_baru"></b-form-input>
            <strong v-else>{{ item.harga_dasar }}</strong>
          </template>
          <template #cell(harga_beli)="{ item, index }">
            <b-form-input @keyup="doFormatRupiahHB(index)" v-model="item.harga_beli"></b-form-input>
          </template>
          <template #cell(diskon)="{ item, index }">
            <b-form-input @keyup="doFormatRupiahDiskon(index)" v-model="item.diskon"></b-form-input>
          </template>
          <template #cell(qty)="{ item }">
            <b-form-input type="number" v-model="item.qty"></b-form-input>
          </template>
          <template #cell(satuan)="{ item }">
            {{ item.satuan ? item.satuan.satuan : '' }}
          </template>
          <template #cell(isChangePrice)="{ item, index }">
            <b-form-checkbox v-model="item.isChangePrice" @change="onChangePrice(index)"></b-form-checkbox>
          </template>
        </b-table>
        <b-row>
          <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
              class="my-0" />
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <div class="alert alert-danger p-2"><strong>{{ $t('Data tidak ada') }}</strong></div>
      </template>
    </b-overlay>
    <template #modal-footer>
      <b-button @click.prevent="submit" variant="primary">Pilih Barang</b-button>
    </template>
  </b-modal>
</template>
<script>
import { BFormSelectOption, BModal, BOverlay, BFormGroup, BInputGroup, BFormCheckbox, BFormInput, BInputGroupAppend, BButton, BTable, BRow, BCol, BFormSelect, BPagination, } from 'bootstrap-vue'
export default {
  props: ['barangs'],
  components: {
    BFormSelectOption, BModal, BOverlay, BFormGroup, BInputGroup, BFormCheckbox, BFormInput, BInputGroupAppend, BButton, BTable, BRow, BCol, BFormSelect, BPagination,
  },
  data: () => ({
    //List Barang
    category: null,
    categoryOptions: [],
    pageOptions: [10, 20, 50, 100],
    sortBy: "desc",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    perPage: 10,
    totalRows: 1,
    currentPage: 1,
    loading: false,
    allChecked: false,
    selectedBarangs: [],
    fields: [
      { key: 'checkbox', label: '#' },
      { key: 'fotopro', label: 'Foto' },
      { key: 'kode', label: 'Barcode' },
      { key: 'nama', label: 'Nama Barang' },
      // { key: 'varian', label: 'Varian', sortable: true },
      { key: 'harga_dasar', label: 'Harga Beli Awal' },
      { key: 'harga_beli', label: 'Harga Beli Supplier' },
      { key: 'isChangePrice', label: 'Ubah Harga Dasar' },
      { key: 'diskon', label: 'Diskon' },
      { key: 'available_stock', label: 'Qty Stok' },
      { key: 'forecast_stock', label: 'Qty Forecast' },
      { key: 'qty', label: 'Qty' },
      { key: 'satuan', label: 'Satuan' },
    ],
    localBarangs: [],
    currentImage: null,
    currentBarcode: null,
  }),
  methods: {
    openModal(src, src2) {
      // Set gambar saat ini dan buka modal
      this.currentImage = src;
      this.currentBarcode = src2;
      this.$refs.imageModal.show();

      // Inisialisasi Viewer.js
      this.$nextTick(() => {
        if (this.viewer) {
          this.viewer.destroy();
        }
        this.viewer = new Viewer(this.$refs.imageViewer, {
          inline: false,
          zoomable: true,
          scalable: true
        });
      });
    },
    getDataKategori() {
      this.$store.dispatch('kategori/getData', {})
        .then(() => {
          this.categoryOptions = this.$store.state.kategori.datas
          this.categoryOptions.map((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          // this.totalRows = this.items.length;
        })
        .catch(e => console.error(e))
    },
    async getData() {
      // const jenis = this.isOwner ? 1 : 2
      // const payload = {
      //   order: "desc",
      //   start: 1,
      //   length: 10,
      // };
      this.loading = true
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: this.sortBy ? this.sortBy : null,
        start: currentPage,
        length: this.perPage,
        // filter_by: "nama_lengkap",
        show_stock: 1,
        kategori_id: this.category,
      };
      // if (this.isGM) payload.jenis = 2;
      this.$store
        .dispatch("barang/getData", payload)
        .then((response) => {
          let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          let items_total = this.$store.state.barang.totals;
          this.localBarangs = items
          this.totalRows = items_total
          this.localBarangs.map((barang) => {
            barang.qty = 0;
            barang.diskon = 0;
            barang.harga_beli = 0;
            barang.isChangePrice = false;
            barang.harga_dasar_baru = 0; // bila ingin merubha harga dasar
          });
          this.loading = false
        })
    },
    doFormatRupiahHB(index) {
      const barangs = Object.assign([], this.localBarangs)
      barangs[index].harga_beli = this.formatRupiah(barangs[index].harga_beli)
      // update reactivity
      this.localBarangs = barangs
    },
    doFormatRupiahHDB(index) {
      const barangs = Object.assign([], this.localBarangs)
      barangs[index].harga_dasar_baru = this.formatRupiah(barangs[index].harga_dasar_baru)
      // update reactivity
      this.localBarangs = barangs
    },
    doFormatRupiahDiskon(index) {
      const barangs = Object.assign([], this.localBarangs)
      barangs[index].diskon = this.formatRupiah(barangs[index].diskon)
      // update reactivity
      this.localBarangs = barangs
    },
    onChangePrice(index) {
      const barangs = Object.assign([], this.localBarangs)
      const isThereChange = this.localBarangs.some(barang => barang.isChangePrice == true)

      if (isThereChange) {
        this.fields = [
          { key: 'checkbox', label: '#' },
          { key: 'fotopro', label: 'Foto' },
          { key: 'kode', label: 'Barcode' },
          // { key: 'kategori', label: 'Kategori' },
          { key: 'nama', label: 'Nama Barang' },
          // { key: 'varian', label: 'Varian', sortable: true },
          { key: 'harga_dasar', label: 'Harga Beli Awal' },
          { key: 'harga_beli', label: 'Harga Beli Supplier' },
          { key: 'isChangePrice', label: 'Ubah Harga Dasar' },
          { key: 'harga_dasar_baru', label: 'Harga Baru' },
          { key: 'diskon', label: 'Diskon' },
          { key: 'available_stock', label: 'Qty Stok' },
          { key: 'forecast_stock', label: 'Qty Forecast' },
          { key: 'qty', label: 'Qty' },
          { key: 'satuan', label: 'Satuan' },
        ]

        setTimeout(() => {
          this.localBarangs = barangs
        }, 500)
      }
      else {
        this.fields = [
          { key: 'checkbox', label: '#' },
          { key: 'fotopro', label: 'Foto' },
          { key: 'kode', label: 'Barcode' },
          // { key: 'kategori', label: 'Kategori' },
          { key: 'nama', label: 'Nama Barang' },
          // { key: 'varian', label: 'Varian', sortable: true },
          { key: 'harga_dasar', label: 'Harga Beli Awal' },
          { key: 'harga_beli', label: 'Harga Beli Supplier' },
          { key: 'isChangePrice', label: 'Ubah Harga Dasar' },
          { key: 'diskon', label: 'Diskon' },
          { key: 'available_stock', label: 'Qty Stok' },
          { key: 'forecast_stock', label: 'Qty Forecast' },
          { key: 'qty', label: 'Qty' },
          { key: 'satuan', label: 'Satuan' },
        ]

        setTimeout(() => {
          this.localBarangs = barangs
        }, 500)
      }
    },
    submit() {
      if (this.selectedBarangs.length < 1) {
        this.displayError({
          message: 'Harap pilih barang!'
        })
        return false
      }
      const thereZeroQty = this.selectedBarangs.some(barang => parseInt(barang.qty) < 1)
      if (thereZeroQty) {
        this.displayError({
          message: 'Kuantitas barang yang dipilih harus melebihi 0!'
        })
        return false
      }

      this.selectedBarangs.map(barang => {
        barang.harga_beli = this.unFormatRupiah(barang.harga_beli)
        barang.harga_dasar_baru = this.unFormatRupiah(barang.harga_dasar_baru)
        barang.diskon = this.unFormatRupiah(barang.diskon)
      })

      this.$emit('submit', this.selectedBarangs)
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    allChecked(val) {
      if (val) {
        this.selectedBarangs = this.localBarangs
      }
      else {
        this.selectedBarangs = []
      }
    },
    localBarangs(val) {
      if (val) {
        this.localBarangs = val
      }
    },

    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
  },

  async mounted() {
    this.getData()
    this.getDataKategori()

  },
}
</script>